import { makeAutoObservable } from 'mobx';
import { IClientUser } from 'shared/interfaces/Admin';
import ApiCalls from '../../apiCalls';
import { Organization } from '../superAdmin/organization';
import { parentAppState } from '../../parentAppState';

export class User {
  public id: string;

  public name: string;

  public email: string;

  public custodian: boolean;

  public inspector: boolean;

  public permissions: any;

  public org_id: string;

  public profile: { firstName?: string; lastName?: string };

  constructor({
    _id,
    org_id,
    profile,
    email,
    permissions,
    site_id,
  }: IClientUser & { site_id: string }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.id = _id;
    this.org_id = org_id;
    this.profile = profile;
    this.name = `${profile.firstName} ${profile.lastName}`.trim();
    this.email = email;
    this.permissions = permissions;
    this.custodian = !!permissions.modules.dabbleCUSTODIAN?.custodian?.site_ids.find(
      (val) => val === site_id,
    );
    this.inspector = !!permissions.modules.dabbleQC?.inspector?.site_ids.find(
      (val) => val === site_id,
    );
  }

  public get _id() {
    return this.id;
  }

  public setOrg(org: Organization, keepPage?: boolean) {
    this.org_id = org.id;
    ApiCalls.patchUser(this, { org_id: this.org_id }).then(() => {
      if (window.location.href.indexOf('superAdmin') > -1) {
        window.location.reload();
      } else {
        const newPage = keepPage
          ? window.location.href
          : `/${parentAppState.params.module}`;
        window.location.href = newPage;
      }
    });
  }

  public logout() {
    ApiCalls.logout()
      .then(() => window.localStorage.removeItem('token'))
      .then(() => {
        window.location.reload();
      });
  }

  public get label() {
    return `${this.name} (${this.email})`;
  }

  public get value() {
    return this.id;
  }
}
